import Carousel from "nuka-carousel";
import { useEffect, useState } from "react";
import Banner from "./createBanners";
import bannerDetails from "./bannerContent.json";
import { useLocation } from 'react-router-dom';

function BannerCarousel() {

	const [isDark, setIsDark] = useState(false);
	const [slideIndex, setSlideIndex] = useState(0);
	const [banners, setBanners] = useState([]);
	const [loading, setLoading] = useState(true);
	const [refreshFlag, setRefreshFlag] = useState(sessionStorage.getItem("hasRefresh") || true);
	const location = useLocation();
	// const history = useNavigate();

	const refreshPage = () => {
		var refreshInd = sessionStorage.getItem("hasRefresh");
		var rereshAbout = sessionStorage.getItem("hasRefresh-about");
		
if (location.pathname === '/') {
		if (refreshInd === null) {
			sessionStorage.setItem("hasRefresh", "true");
			setTimeout(() => {
				window.location.reload();
			}, 500); // 500 milliseconds delay
		}
	}

		if (location.pathname === '/about-us') {
			if (rereshAbout === null) {
				sessionStorage.setItem("hasRefresh-about", "true");
				setTimeout(() => {
					window.location.reload();
				}, 500); // 500 milliseconds delay
			}
		  }
		
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setSlideIndex((prevIndex) => (prevIndex + 1) % totalSlides);
		}, 4000);

		return () => clearInterval(interval);
	}, [slideIndex]);

	useEffect(() => {
		// This effect will run when the location changes
		if (location.pathname !== '/') {
		  // If the current path is not the homepage, delete the item from sessionStorage
		  sessionStorage.removeItem('hasRefresh');
		}

		if (location.pathname !== '/about-us') {
			// If the current path is not the homepage, delete the item from sessionStorage
			sessionStorage.removeItem('hasRefresh-about');
		  }
	  }, [location]);

	useEffect(() => {
		let loadedCount = 0;
		const totalBanners = bannerDetails.length;

		const onBannerLoaded = () => {
			loadedCount++;
			if (loadedCount === totalBanners) {
				setLoading(false);
			}
		};

		const fetchBanners = () => {
			const bannerComponents = bannerDetails.map((bannerDetail, index) => (
				<Banner
					key={index}
					details={bannerDetail}
					onLoaded={onBannerLoaded}
				/>
			));
			setBanners(bannerComponents);

		};

		fetchBanners();
		refreshPage();

	}, []);

	const totalSlides = bannerDetails.length;

	const dotStyles = {
		backgroundColor: isDark ? 'lightgray' : "darkgray",
		width: '12px',
		height: '12px',
		margin: '12px 5px',
		border: 'none',
		borderRadius: '50%',
		outline: 'none',
	};

	const activeDotStyles = {
		backgroundColor: isDark ? 'darkgray' : "black",
		width: '12px',
		height: '12px',
		margin: '12px 5px',
		border: 'none',
		borderRadius: '50%',
		outline: 'none',
	};

	return (
		<>
			<Carousel
				dragging={false}
				slideIndex={slideIndex}
				renderBottomCenterControls={({ currentSlide, goToSlide }) => (
					<div>
						{Array.from({ length: 3 }).map((_, index) => (
							<button
								key={index}
								style={index === currentSlide ? activeDotStyles : dotStyles}
								onClick={() => {
									setSlideIndex(index);
									goToSlide(index)
								}}
							/>
						))}
					</div>
				)}
				style={{ width: "100%" }}
				wrapAround={true}
				afterSlide={(index) => {

					index === 1 ? setIsDark(false) : setIsDark(true)
				}}
				slidesToShow={1}

				autoplay={false}
				cellSpacing={1}
				withoutControls={false}
				renderCenterLeftControls={({ previousSlide }) => (
					<button
						onClick={() => {
							setSlideIndex((slideIndex - 1) % totalSlides);
							previousSlide()
						}}
						style={{
							margin: '12px',
							borderRadius: "50%",
							backgroundColor: isDark ? "white" : "black",
							width: "40px",
							height: "40px",
							color: !isDark ? "white" : "black",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							border: "none",
							cursor: "pointer",
						}}
					>
						<i className="fa fa-arrow-left" />
					</button>
				)}
				renderCenterRightControls={({ nextSlide }) => (
					<button
						onClick={() => {
							setSlideIndex((slideIndex + 1) % totalSlides);
							nextSlide()
						}}
						style={{
							margin: '12px',
							borderRadius: "50%",
							backgroundColor: isDark ? "white" : "black",
							width: "40px",
							height: "40px",
							color: !isDark ? "white" : "black",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							border: "none",
							cursor: "pointer",
						}}
					>
						<i className="fa fa-arrow-right" />
					</button>
				)}
			>
				{banners}
			</Carousel>
		</>
	);
}

export default BannerCarousel;
