import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
	CDBFooter,
	CDBFooterLink,
	CDBBtn,
	CDBIcon,
	CDBContainer,
	CDBBox,
} from "cdbreact";
import { ReactComponent as XIcon } from "../assets/images/x.svg";
import logo from "../assets/images/Logo-3D-White-Text-0100.png";
import ContactUsPageMainComponent from "./contactUsPage/contactUsMainComponent";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
	const [isMobile, setIsMobile] = useState(false);
	const [contactUsModalShow, setContactUsModalShow] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="footer">
			{!isMobile ? (
				<Container>
					<div
						style={{
							padding: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Navbar.Brand href="/">
							<img
								src={logo}
								height="30px"
								className="d-inline-block align-top"
								alt="Smart-Biology Inc."
							/>
						</Navbar.Brand>
						<div>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								&copy; 2015-2023
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Smart Biology, All rights reserved.
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Cell and Molecular Biology Animation Education.
							</small>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "0px",
								paddingLeft: "20px",
							}}
						/>
						<div>
							{" "}
							<a
								style={{
									color: "white",
									textDecoration: "none",
									fontSize: "12px",
								}}
								href="/privacy-and-terms"
							>
								Terms & Conditions | Privacy Statement
							</a>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "20px",
								paddingLeft: "20px",
							}}
						/>
						<div>
						<Nav>
						<Nav.Link
						style={{color: 'white'}}
						onClick={() => {
							setContactUsModalShow(true);
						}}>
							
							Contact Us
						</Nav.Link>
						</Nav>
						</div>
						<div
							style={{
								borderLeft: "1px solid white",
								height: "50px",
								marginLeft: "20px",
								paddingLeft: "20px",
							}}
						/>
						<CDBBox
							display="flex"
							justifyContent="start"
							style={{ width: "30%" }}
						>
							<a
								style={{ margin: "20px" }}
								href="https://www.facebook.com/smartbiology3d/"
							>
								<div className="sr-only">opens facebook.com</div>
								<CDBIcon
									style={{ color: "white" }}
									size="2x"
									fab
									icon="facebook-f"
								/>
							</a>

							<a
								style={{ margin: "20px" }}
								href="https://twitter.com/SmartBiology3D"
							>
								<div className="sr-only">opens x.com</div>
								<CDBIcon
									style={{ color: "white" }}
									size="2x"
									fab
									icon="twitter"
								/>
							</a>
							<a
								style={{ margin: "20px" }}
								href="https://www.instagram.com/smartbiology3d/"
							>
								<div className="sr-only">opens instagram.com</div>
								<CDBIcon
									style={{ color: "white" }}
									size="2x"
									fab
									icon="instagram"
								/>
							</a>
							<a
								style={{ margin: "20px" }}
								href="https://www.linkedin.com/company/smart-biology/mycompany/"
							>
								<div className="sr-only">opens linked-in.com</div>
								<CDBIcon
									style={{ color: "white" }}
									size="2x"
									fab
									icon="linkedin"
								/>
							</a>
						</CDBBox>
					</div>
				</Container>
			) : (
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<div
						style={{
							padding: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Navbar.Brand href="/">
							<img
								src={logo}
								height="20px"
								className="d-inline-block align-top"
								alt="Smart-Biology Inc."
							/>
						</Navbar.Brand>
						<div>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								&copy; 2015-2023
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Smart Biology, All rights reserved.
							</small>
							<small
								style={{
									color: "white",
									width: "200px",
									fontSize: "10px",
									textAlign: "left",
									display: "block",
								}}
							>
								Cell and Molecular Biology Animation Education.
							</small>
						</div>
					</div>
					<div>
						{" "}
						<a
							style={{
								color: "white",
								textDecoration: "none",
								fontSize: "12px",
							}}
							href="/privacy-and-terms"
						>
							Terms & Conditions | Privacy Statement
						</a>
					</div>
					<div>
						<Nav>
						<Nav.Link
						onClick={() => {
							setContactUsModalShow(true);
						}}>
							
							Contact Us
						</Nav.Link>
						</Nav>
					</div>
					<CDBBox
						display="flex"
						style={{ width: "100%", justifyContent: "center" }}
					>
						<a
							style={{ margin: "20px" }}
							href="https://www.facebook.com/smartbiology3d/"
						>
							<span>opens facebook.com</span>
							<CDBIcon
								style={{ color: "white" }}
								size="2x"
								fab
								icon="facebook-f"
							/>
						</a>
						<a
							aria-label="opens x.com"
							style={{ margin: "20px" }}
							href="https://twitter.com/SmartBiology3D"
						>
							<CDBIcon
								style={{ color: "white" }}
								size="2x"
								fab
								icon="twitter"
							/>
						</a>
						<a
							aria-label="opens instagram.com"
							style={{ margin: "20px" }}
							href="https://www.instagram.com/smartbiology3d/"
						>
							<CDBIcon
								style={{ color: "white" }}
								size="2x"
								fab
								icon="instagram"
							/>
						</a>
						<a
							aria-label="opens linked in.com"
							style={{ margin: "20px" }}
							href="https://www.linkedin.com/company/smart-biology/mycompany/"
						>
							<CDBIcon
								style={{ color: "white" }}
								size="2x"
								fab
								icon="linkedin"
							/>
						</a>
					</CDBBox>
				</Container>
			)}
			<Modal
				show={contactUsModalShow}
				onHide={() => setContactUsModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<ContactUsPageMainComponent
				></ContactUsPageMainComponent>
			</Modal>{" "}
		</div>
	);
}

export default Footer;
