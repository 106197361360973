import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
// import Form from 'react-bootstrap/Form';
import { getAllOrders, getOrderByID } from '../../services/bffServices/getOrderDetails';
import OrderModelComponentV2 from './orderModelv2';
import Pagination from 'react-bootstrap/Pagination'
import Datetime from 'react-datetime';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import ReactExport from "react-export-excel";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'
import Placeholder from 'react-bootstrap/Placeholder';
import { MdOutlineCancel, MdOutlineCheck } from "react-icons/md";
import { IconContext } from "react-icons";
import { Form, FormCheck } from 'react-bootstrap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let pageSize = 10;

function AdminOrderListComponent(props) {
  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [orderList, setOrderList] = useState([{}]);
  const [originalOrderList, setOriginalOrderList] = useState([{}]);
  const [orderPriceBreakdown, setorderPriceBreakdown] = useState();
  const [orderProductInfo, setOrderProductInfo] = useState();
  const [activeDataSet, setActiveDataSet] = useState();
  const [activeOrder, setActiveOrder] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [startDate, setStartDate] = useState(moment(moment().subtract(30, 'days').calendar()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [searchOrder, setSearchOrder] = useState("");
  const [filterTrial, setFilterTrial] = useState(false);//false means keep in the list
  const [downloadDataSet, setDownloadDataSet] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheck = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (isChecked) {
      console.log("isChecked true " + isChecked);
      filterOrders("qbSyncErrors", null)
    }

    if (!isChecked) {
      console.log("!isChecked false: " + isChecked);
      filterOrders("RESET", null)
    }
  }, [isChecked]);
  const [tableData, setTableData] = useState(() => {
    return (
      <>
        <Placeholder md={12} />
      </>
    )
  });

  const currentTableData = async (theOrder, currentPage) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setActiveDataSet(theOrder.slice(firstPageIndex, lastPageIndex));
  };

  var filterOrders = async (filterType, orderID = null) => {

    setShowLoading(true)
    if (filterType == "DATE") {
      var orderListReponseFilter = await orderListPayload();
      await setOrderList(orderListReponseFilter);
      updatePage(1, orderListReponseFilter);
      await createDownloadDataSet(orderListReponseFilter);
    }

    if (filterType == "ORDER") {
      var singleOrder = await getOrderByID(orderID);
      await setOrderList(singleOrder);
      var myNewArray = [];
      myNewArray[0] = singleOrder
      var numPages = myNewArray.length / 10;
      await setNumberOfPages(Math.round(numPages));
      await setActiveDataSet(myNewArray);
      await createDownloadDataSet(myNewArray);
    }

    if (filterType == "qbSyncErrors") {
      var newOrderList = await filterOrderList("qbSyncErrors");
      console.log("qbSyncErrors" + JSON.stringify(newOrderList))
      await calculateProductTotal(newOrderList);
      await setOrderList(newOrderList);
      updatePage(1, newOrderList);
      await createDownloadDataSet(newOrderList);

    }

    if (filterType == "RESET") {
      var newOrderList = await filterOrderList("RESET");
      console.log("REST" + JSON.stringify(newOrderList))
      await calculateProductTotal(newOrderList);
      await setOrderList(newOrderList);
      updatePage(1, newOrderList);
      await createDownloadDataSet(newOrderList);

    }

    if (filterType == "TRIAL") { //NOT COMPETED!
      if (filterTrial === false) {
        setFilterTrial(true);
        var newOrderList = await filterOrderList("TRIAL");
        await setOrderList(newOrderList);
        updatePage(1, newOrderList);
      }
      if (filterTrial === true) {
        setFilterTrial(false);
      }

    }

    setShowLoading(false)
  }

  const filterOrderList = async (filterType) => {

    var newOrderList = [];
    if (filterType == "TRIAL") {
      var trialProductId = "62e72a061b00ad1172992fe8";

      for (var i = 0; i <= orderList.length; i++) {
        if (orderList[i].product[0].id != trialProductId) {
          newOrderList.push(orderList[i]);
        }
      }
    }

    if (filterType == "qbSyncErrors") {

      for (var i = 0; i < orderList.length; i++) {
        if (orderList[i].hasOwnProperty("qbSyncStatus") && orderList[i].qbSyncStatus.status !== "SUCCESS") {
          newOrderList.push(orderList[i]);
        }
      }

    }

    if (filterType == "RESET") {
      newOrderList = originalOrderList;
    }

    return newOrderList;
  }

  var triggeroverLay = async (order) => {

    // console.log("triggeroverLay =" + JSON.stringify(order))
    setorderPriceBreakdown(order?.orderTotal);
    setOrderProductInfo(order?.product);
    setActiveOrder(order);
    setShowOrderInfo(true)
  }

  var updatePage = async (num, orders) => {

    // console.log('updatePage');
    currentTableData(orders, num);
    var numPages = orders.length / 10;
    await setNumberOfPages(Math.round(numPages));
  }

  var pageinationBar = () => {

    var html = [];

    for (var i = 1; i <= numberOfPages; i++) {
      html.push(<button class="page-link" onClick={event => updatePage(event.target.value, orderList)} value={i}> {i} </button>);
    }

    return (
      <>
        {html}
      </>
    )
  }

  var orderListPayload = async () => {
    var blah = await getAllOrders(startDate, endDate);
    // console.log("blahblah" + startDate + endDate);
    if (blah != null || blah != undefined) {
      setOrderList(blah);
      setOriginalOrderList(blah);
      await calculateProductTotal(blah);
      return blah;
    }
  }

  const [productTotals, setProductTotals] = useState();
  async function calculateProductTotal(orders) {
    const productTotals = {};
  
    for (const order of orders) {
      const productName = order?.productNameDisplay;
      const total = order.orderTotal?.Total;
  
      if (productName && total !== null) { // Check for both product name and total
        if (!productTotals[productName]) {
          productTotals[productName] = {
            productName,
            productQty: 0,
            total: 0,
          };
        }
  
        productTotals[productName].productQty += 1;
        productTotals[productName].total += total;
      }
    }
  
    // Only set productTotals if it's not empty
    if (Object.values(productTotals).length > 0) {
      setProductTotals(Object.values(productTotals));
    } else {
      setProductTotals(null); // Or set it to an empty array if you prefer
    }
  }

  var calcualteRevenueTotal = async () => {

  }

  const createDownloadDataSet = async (orderInfo) => {

    var downloadDataSetArray = [];

    for (var i = 0; i < orderInfo.length; i++) {

      downloadDataSetArray.push({
        "id": orderInfo[i]?.id,
        "date": orderInfo[i]?.date,
        "name": orderInfo[i]?.customer?.name,
        "productNameDisplay": orderInfo[i]?.productNameDisplay,
        "province": orderInfo[i]?.customer?.province,
        "postalCode": orderInfo[i]?.customer?.postCode,
        "country": orderInfo[i]?.customer?.country,
        "subTotal": orderInfo[i]?.orderTotal?.Total,
        "tax": orderInfo[i]?.orderTotal?.taxes,
        "totalDiscounts": orderInfo[i]?.orderTotal?.totalDiscounts,
        "total": orderInfo[i]?.orderTotal?.Total,
        "taxTotalPercent": orderInfo[i]?.orderTotal?.tax?.TotalPercent,
        "orderStatus": orderInfo[i]?.orderStatus,
        "trialOrder": orderInfo[i]?.trialOrder,
        "qbSyncStatus": orderInfo[i]?.qbSyncStatus?.status,
        "qbSyncId": orderInfo[i]?.qbSyncStatus?.qbId,
      })
    }

    setDownloadDataSet(downloadDataSetArray);

  }

  return (
    <>
      <div style={{ textAlign: "left", verticalAlign: "middle" }}><h6>Filters</h6></div>
      <div style={{ fontSize: "12px", textAlign: "left", 'padding-bottom': "10px" }}>To force an LTI gradown below. This will update their grades with the latest information to your external learning platform.</div>

      <div style={{ fontSize: "16px" }}>
        <table>
          <tr>
            <td style={{ padding: "5px" }}>
              <label for="start">Start date: </label>
            </td>
            <td style={{ padding: "5px" }}>
              <Datetime closeOnSelect={true} dateFormat="YYYY-MM-DD" onChange={newDate => setStartDate(moment(newDate).format("YYYY-MM-DD"))} timeFormat={false} value={startDate} />
            </td>
            <td style={{ padding: "5px", }}>
              <label for="start">End date: </label>
            </td>
            <td style={{ padding: "5px" }}>
              <Datetime closeOnSelect={true} dateFormat="YYYY-MM-DD" onChange={newDate => setEndDate(moment(newDate).format("YYYY-MM-DD"))} timeFormat={false} value={endDate} />
            </td>
            <td style={{ padding: "5px" }}>
              <Button size="sm" onClick={() => filterOrders("DATE")}>Filter by Date</Button>
            </td>
            <td style={{ 'border-right': "1px solid black", padding: "5px" }}>
            </td>
            <td style={{ padding: "5px", 'padding-left': "15px" }}>
              <input class="form-control form-control-sm" onChange={event => setSearchOrder(event.target.value)} type="text" placeholder="Order ID" autoComplete="text" name="orderID" id="orderID" />
            </td>
            <td style={{ padding: "5px" }}>
              <Button size="sm" onClick={() => filterOrders("ORDER", searchOrder)}>Filter by OrderID</Button>
            </td>
            <td style={{ 'border-right': "1px solid black", padding: "5px" }}>            </td>
            <td style={{ padding: "5px", 'padding-right': "15px" }}>
              <Form>
                <Form.Check
                  type="checkbox"
                  id="qbErrors"
                  label="Show QB Errors"
                  checked={isChecked} // Set checked state
                  onChange={handleCheck} // Call handleCheck on change
                />
              </Form>
            </td>
          </tr>
        </table>
      </div>
      <hr />
      <table>
        <tr>
          <td>
            {Array.isArray(productTotals) && productTotals.length > 0 && productTotals[0] && Object.keys(productTotals[0]).length > 0 &&
              <>
                <table style={{ "font-size": "12px", 'border-collapse': "collapse" }}>
                  <tr>
                    <th style={{ padding: "5px", 'background-color': "#eee", width: "75%" }}>Product</th>
                    <th style={{ padding: "5px", 'background-color': "#eee", width: "10%" }}>Qty</th>
                    <th style={{ padding: "5px", 'background-color': "#eee", width: "15%" }}>Total</th>
                  </tr>
                  {productTotals.map((productTotal) => (
                    <tr>
                      <td style={{ padding: "5px", border: "1px solid #ddd", "text-align": "left", width: "75%" }}>
                        {productTotal.productName}
                      </td>
                      <td style={{ padding: "5px", border: "1px solid #ddd", "text-align": "center", width: "10%" }}>
                        {productTotal.productQty}
                      </td>
                      <td style={{ padding: "5px", border: "1px solid #ddd", "text-align": "right", width: "15%" }}>
                        ${productTotal?.total?.toFixed(2) || "0"}
                      </td>
                    </tr>
                  ))}
                </table>
              </>
            }
          </td>

        </tr>
      </table><br />
      <Table striped bordered hover style={{ 'font-size': "10px" }}>
        <thead>
          <tr>
            <th width="10%">Order #</th>
            <th width="5%">Date</th>
            <th width="10%">Customer</th>
            <th width="20%">Product</th>
            <th width="5%">Total</th>
            <th width="5%">Status</th>
            <th width="5%">Trial Order</th>
            <th width="5%">QB Sync Status</th>
            <th width="5%">QB Id</th>
          </tr>
        </thead>
        <tbody>
          {
            // First, check if activeDataSet is an array
            Array.isArray(activeDataSet) && activeDataSet.length > 0 ?  (
              // Next, check if the length of the array is greater than 0
              activeDataSet.length > 0 ? (
                activeDataSet.map(order => (
                  <tr key={order.id}>
                    <td><Alert.Link onClick={() => triggeroverLay(order)}>{order.id}</Alert.Link></td>
                    <td>{order.date}</td>
                    <td width="10%">{order.customer?.name}</td>
                    <td>{order.productNameDisplay}</td>
                    <td>${order.orderTotal?.Total}</td>
                    <td>{order.orderStatus}</td>
                    <td>{order.trialOrder}</td>
                    <td>
                      {order?.qbSyncStatus?.status === 'SUCCESS' ? (
                        <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "2em" }}>
                          <MdOutlineCheck role='img' aria-label='Sync Successful' /><br /><small>SUCCESS</small>
                        </IconContext.Provider>
                      ) : order?.qbSyncStatus?.status !== 'SUCCESS' && order?.qbSyncStatus ? (
                        <IconContext.Provider value={{ color: "red", className: "global-class-name", size: "2em" }}>
                          <MdOutlineCancel role='img' aria-label='Sync Unsuccessful' /> <br /><small>ERROR  </small>
                        </IconContext.Provider>
                      ) : (
                        <span>Not Completed</span>)}
                    </td>
                    <td>{order?.qbSyncStatus?.qbId || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">Please search for orders</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="7">Error Retrieving orders</td>
              </tr>
            )
          }

        </tbody>
      </Table>
      <div align="center">
        <Row>
          <Col>
            <Pagination>{pageinationBar()}</Pagination>
          </Col>
          <Col>
            <div align="right">
              <ExcelFile element={<Button>Download</Button>}>
                <ExcelSheet data={downloadDataSet} name="Orders">
                  <ExcelColumn label="Order Date" value="date" />
                  <ExcelColumn label="Order ID" value="id" />
                  <ExcelColumn label="Customer Name" value="name" />
                  <ExcelColumn label="Province" value="province" />
                  <ExcelColumn label="Postal Code" value="postalCode" />
                  <ExcelColumn label="Country" value="country" />
                  <ExcelColumn label="Product" value="productNameDisplay" />
                  <ExcelColumn label="Subtotal" value="subTotal" />
                  <ExcelColumn label="Tax" value="tax" />
                  <ExcelColumn label="Total Discounts" value="totalDiscounts" />
                  <ExcelColumn label="Order Total" value="total" />
                  <ExcelColumn label="Tax % applied" value="taxTotalPercent" />
                  <ExcelColumn label="Status" value="orderStatus" />
                  <ExcelColumn label="Trial Order" value="trialOrder" />
                  <ExcelColumn label="QB Status" value="qbSyncStatus" />
                  <ExcelColumn label="QB Id" value="qbSyncId" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        show={showOrderInfo}
        onHide={() => setShowOrderInfo(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <OrderModelComponentV2 orderProductInfo={orderProductInfo} orderPriceBreakdown={orderPriceBreakdown} orderList={activeOrder} />
      </Modal>

      <Modal
        show={showLoading}
        backdrop="static"
        centered
      >
        <div class="centerLoadingModel" >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Modal>
    </>
  );
}

export default AdminOrderListComponent;