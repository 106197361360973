import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import LoginCheck from "../components/header/student-view";
import NotebookModel from "../components/header/student-view";
import { loginCheck } from "../services/bffServices/isLoggedIn";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import AlertSB from "../components/alertSB";
import logo from "../assets/images/Logo-3D-Black-Text-0100.png";
import Modal from "react-bootstrap/Modal";
import ContactUsPageMainComponent from "./contactUsPage/contactUsMainComponent";

const gitHubUrl = process.env.REACT_APP_BFF + "/getHeaderLinks";
const getSessionEndpoint = process.env.REACT_APP_BFF + "/getSessionDetails";
const options = {
	method: "GET",
	headers: new Headers({
		"Content-Type": "text/plain",
		"sb-application-name": "sb-site-ui",
		"sb-application-key": "jmd-test",
		"Access-Control-Allow-Origin": "*",
		sessionID: sessionStorage.getItem("sessionID"),
		"Access-Control-Allow-Headers":
			"Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ",
	}),
	mode: "cors",
	credentials: "omit",
};

function App2() {
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);
	const [isLoggedInCheck, setisLoggedInCheck] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const [homeLink, setHomeLink] = useState("Home");
	const [exploreLink, setExploreLink] = useState("Subjects");
	const [contactUs, setContactUs] = useState("Contact");
	const [langSelector, setLangSelector] = useState("Language");
	const [enDropdown, setEnDropDown] = useState("English");
	const [frDropdown, setFnDropDown] = useState("Français");
	const [lang, setLang] = useState("blah");
	const [filterModalShow, setFilterModalShow] = useState(false);
	const [enterpriseModalShow, setEnterpriseModalShow] = useState(false);
	const [headerAlignment, setHeaderAlignment] = useState(
		"justify-content-end flex-grow-1 pe-3"
	);

	const [isScrolledToTop, setIsScrolledToTop] = useState(true);



	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Check initial screen width
		window.addEventListener("resize", handleResize); // Update on resize

		return () => {
			window.removeEventListener("resize", handleResize); // Cleanup
		};
	}, []);


	const getData = () => {
		//Remove, no longer needed
		fetch(gitHubUrl, options)
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				setData(myJson);
			});
	};

	const getLoggedInStatus = async () => {
		var value = {};
		try {
			value = await loginCheck();
		} catch (err) {
			console.log(err);
		}
		setisLoggedInCheck(value.validLogin);
	};

	useEffect(() => {

		function handleScroll() {
			if (window.scrollY === 0) {
				setIsScrolledToTop(true);
			} else {
				setIsScrolledToTop(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		try {
			const language = sessionStorage.getItem("lang") || "EN";

			if (language == "FR") {
				setHomeLink("Accueil");
				setExploreLink("Sujettes");
				setContactUs("Nous joindre");
				setLangSelector("Langue");
				setEnDropDown("English");
				setFnDropDown("Français");
			}
			getLoggedInStatus();
			setLang("test");

			if (isLoggedInCheck === true) {
				setHeaderAlignment("justify-content-start flex-grow-1 pe-3");
			}
		} catch (err) {
			console.log(err);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleSelect = (eventKey) => {
		if (eventKey == "EN") {
			sessionStorage.setItem("lang", "EN");
			window.location.reload(false);
		}
		if (eventKey == "FR") {
			sessionStorage.setItem("lang", "FR");
			window.location.reload(false);
		}
	};
	return (
		<Navbar
			collapseOnSelect
			expand="lg"
			bg="white"
			variant="light"
			sticky="top"
			className={isScrolledToTop ? "" : "shadow"}
			style={{ height: "60px", "font-weight": "400", backgroundColor: 'white' }}
			onSelect={handleSelect}
		>
			<Container>
				<Navbar.Brand href="/">
					{" "}
					<img
						src={logo}
						height="30"
						className="d-inline-block align-top"
						alt="Smart-Biology Inc."
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav
						className="navbar-item"
						style={{ justifyContent: "end", flexGrow: 1 }}
					>
						<Nav.Link style={{ marginRight: "20px" }} href="/about-us">
							About
						</Nav.Link>
						<Nav.Link 
						style={{ marginRight: "20px" }} 
						onClick={() => {
							setFilterModalShow(true);
						}}>
							Classroom
						</Nav.Link>
						<Nav.Link
							style={{ marginRight: "20px" }}
							href="https://smart-biology-academy.getlearnworlds.com/courses"
		
						>
							Academy
						</Nav.Link>
						<Nav.Link
						onClick={() => {
							setEnterpriseModalShow(true);
						}}>
							
							Enterprise
						</Nav.Link>
					</Nav>
					{!isMobile ? <div className="vertical-divider"></div> : <></>}
					<Nav className="navbar-item">
						<LoginCheck isLoggedIn={isLoggedInCheck} />
						<div style={{ margin: "auto" }}>
							<small>
								<NavDropdown title={langSelector} id="nav-dropdown">
									<NavDropdown.Item
										onClick={(event) => handleSelect(event)}
										value="EN"
										eventKey="EN"
									>
										{enDropdown}
									</NavDropdown.Item>
									<NavDropdown.Item value="FR" eventKey="FR">
										{frDropdown}
									</NavDropdown.Item>
								</NavDropdown>
							</small>
						</div>
					</Nav>
				</Navbar.Collapse>
			</Container>
			<Modal
				show={filterModalShow}
				onHide={() => setFilterModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<div style={{ padding: '24px', textAlign: "center" }}>
					<p>For students in a class using Smart Biology, or for Instructors looking to adopt.</p>
					<h3 style={{  }}>I'm a classroom:</h3>
					<br></br>
					<div
						style={{
							display: "flex",
							textAlign: "center",
							justifyContent: "center",
						}}
					>
						<a href="buy/class">
							<Button
								size="lg"
								style={{ marginRight: "20px", marginLeft: "20px" }}
							>
								Student
							</Button>
						</a>
						<a href="/subjects">
							<Button
								size="lg"
								style={{ marginRight: "20px", marginLeft: "20px" }}
							>
								Educator
							</Button>
						</a>
					</div>
				</div>
			</Modal>
			<Modal
				show={enterpriseModalShow}
				onHide={() => setEnterpriseModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				{/* <div style={{ padding: '24px', textAlign: "center" }}>
					<p>For students in a class using Smart Biology, or for Instructors looking to adopt.</p>
					<h3 style={{  }}>I'm a classroom:</h3>
					<br></br>
					<div
						style={{
							display: "flex",
							textAlign: "center",
							justifyContent: "center",
						}}
					>
						<a href="buy/class">
							<Button
								size="lg"
								style={{ marginRight: "20px", marginLeft: "20px" }}
							>
								Student
							</Button>
						</a>
						<a href="/subjects">
							<Button
								size="lg"
								style={{ marginRight: "20px", marginLeft: "20px" }}
							>
								Educator
							</Button>
						</a>
					</div>
				</div> */}
				<ContactUsPageMainComponent type={'enterprise'}></ContactUsPageMainComponent>
			</Modal>				
		</Navbar>
	);
}

export default App2;
