import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createNote } from "./services/createNote";
import { getChapterName } from "./services/lib";
import moment from "moment";
import EditorToolbar, { modules, formats } from "./note-toolbar";
import { Quill } from "react-quill";

function CreateNoteComponent(props) {
	const [showCreateNote, setshowCreateNote] = useState(false);
	const [noteSummary, setNoteSummary] = useState("");
	const [notes, setNotes] = useState("");
	const [unit, setUnit] = useState(props?.unit || "N/A");
	const [date, setDate] = useState(moment().format("LLLL"));
	const [chapter, setChapter] = useState(props?.chapter || "N/A");
	const [module, setModule] = useState(props?.module || "N/A");
	const handleClose = () => setshowCreateNote(false);
	const handleShow = () => setshowCreateNote(true);
	const language = sessionStorage.getItem("lang") || "EN";
	//lang
	const [buttonName, setButtonName] = useState("Create Note");
	const [modalTitle, setModalTitle] = useState("");
	const [dateLabel, setDateLabel] = useState("");
	const [summaryLabel, setSummaryLabel] = useState("");
	const [closeButton, setCloseButton] = useState("");
	const [submitButton, setSubmitButton] = useState("");

	const createNotesMain = async () => {
		handleClose();

		var notePayload = {
			unit: unit,
			chapter: chapter,
			module: module,
			summary: noteSummary,
			notes: notes,
			date: date,
		};

		createNote(notePayload);
	};

	useEffect(async () => {
		var chapterName = await getChapterName("61788a6d23d456322035bbb2");
		setChapter(chapterName);

		if (language != "FR") {
			setButtonName("Create Note");
			setModalTitle("Create a Note");
			setDateLabel("Date:");
			setSummaryLabel("Summary");
			setCloseButton("Close");
			setSubmitButton("Save Changes");
		}

		if (language == "FR") {
			setButtonName("Créer une note");
			setModalTitle("Créer une note");
			setDateLabel("Date:");
			setSummaryLabel("Résumé");
			setCloseButton("proche");
			setSubmitButton("Sauvegarder les modifications");
		}
	});

	

	const handleKeyDown = (e) => {
		if (e.key === "Tab") {
			e.preventDefault();

			if (e.shiftKey) {
				document.getElementById("option	").focus();
			} else {
				document.getElementById("closeButton").focus();
			}
		}
	};

	return (
		<>
			<Button variant="info" onClick={handleShow}>
				{buttonName}
			</Button>

			<Modal
				show={showCreateNote}
				onHide={handleClose}
				role="dialog"
				aria-labelledby="dialogHeader"
			>
				<Modal.Header closeButton>
					<h2 id="dialogHeader">{modalTitle}</h2>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form syle={{ height: "100%" }}>
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlInput1"
								>
									<Form.Label for="noteSummary">{summaryLabel}</Form.Label>
									<Form.Control
										id="noteSummary"
										autoComplete="text"
										type="text"
										value={noteSummary}
										onChange={(event) => setNoteSummary(event.target.value)}
										autoFocus
									/>
								</Form.Group>
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlTextarea1"
								>
									<Form.Label for="noteBody">Notes</Form.Label>
									<div style={{ height: "100px" }}>
										<EditorToolbar />
										<ReactQuill
											id="noteBody"
											style={{ height: "100%" }}
											theme="snow"
											value={notes}
											modules={modules}
											formats={formats}
											onChange={setNotes}
											onKeyDown={handleKeyDown}
										/>
									</div>
									<br />
									<br />
									<br />
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col>
							<div>
							<h4><b>{dateLabel}</b> <small> {date}</small></h4> 
							</div>
							<div>
								<h4><b>Module:</b> <small>{module}</small></h4>
								
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button id="closeButton" variant="secondary" onClick={handleClose}>
						{closeButton}
					</Button>
					<Button variant="primary" onClick={() => createNotesMain()}>
						{submitButton}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CreateNoteComponent;
