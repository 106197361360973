import React, { useState } from 'react';

export const AccessibleToolbarButton = ({ option, value, optionName }) => {
  const [isEnabled, setEnabled] = useState(false);

  const handleClick = () => {
    setEnabled(!isEnabled);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <button id='option' className={option} value={value} onClick={handleClick} aria-current={isEnabled} role='img' aria-label={optionName}/>
    </div>
  );
};
