import {getIPAddress} from '../getIPAddress';
export async function login(email,passwordToken){

    var loginPayload = {
                            "userDetails":{
                                "email": email,
                                "passwordToken" : passwordToken,
                                "validLogin": false
                            },
                            // "ipAddress": await getIPAddress(),
                            "ipAddress": "192.168.1.0",
                            "updateExpiryTime": true
                        }
    
    const validateUserEndpoint = process.env.REACT_APP_BFF + "/login";
    const options = { 
      method: 'POST', 
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'sb-application-name': 'sb-site-ui',
        'sb-application-key': 'jmd-test',
        'Access-Control-Allow-Origin': '*',
        'sessionID' : sessionStorage.getItem("sessionID"),
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
      }), 
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(loginPayload)
    };

    const loginResponse = await fetch(validateUserEndpoint,options)
    .then(function(response){return response.json()});
    
    if(loginResponse.userDetails.validLogin){
      sessionStorage.setItem('existingUser', "Y");
    }
    
    return loginResponse;

}

