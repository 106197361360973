import { MdOutline360 } from "react-icons/md";
import { IconContext } from "react-icons";

function ModelViewbox({src}) {
	return (
		<>
			<div className="story-card" style={{
				position: "relative",
				padding: "0px",
				borderRadius: "20px",
				backgroundColor: "white",
			}}>

				<table width="100%">
					<tr>
						<td>
						<iframe
					loading="lazy"
					allowtransparency="true"
					style={{ visibility: "visible", opacity: "1", }}
					src={
						src

					}
					width="100%"
					height="480px"
				>
					<div
						class="whs-app"
						style={{
							width: "inherit",
							height: "inherit",
							position: "relative",
						}}
					>
						<canvas
							style={{ width: "100%", height: "500px!important" }}
						>

						</canvas>
					</div>

				</iframe>
						</td>
					</tr>
					<tr>
						<td>
						<div align="right">
			<IconContext.Provider value={{ color: "green", className: "global-class-name", size: "2em" }}>
				Turn me 360 degrees<MdOutline360 />
				</IconContext.Provider>
				</div>
						</td>
					</tr>
				</table>
			</div>

			<br />
			<br />

		</>
	);
}

export default ModelViewbox;
