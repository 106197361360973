import Container from "react-bootstrap/Container";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Image from "react-bootstrap/Image";
import Gallery from "react-grid-gallery";
const url = require("url");

// const domainURL = "https://www.smart-biology.com" //https://www.smart-biology.com/wp-content/uploads/2020/11/Life-U4-C14-EFE-M1-L1-Figure-1.jpg
const domainURL = "https://storage.googleapis.com/sb-general"; //https://storage.googleapis.com/sb-general/

export default function LessonFiguresComponent(props) {
	const [figures, setFigures] = useState("blah");
	const [lastFocus, setLastFocus] = useState();
	const [figureContent, setFigureContent] = useState("blah");
	const [isGalleryOpen, setGalleryOpen] = useState(false);

	const handleOpenGallery = async () => {
		setLastFocus(document.activeElement);
		await setGalleryOpen(true);
		document.getElementById("closeButton").focus();
	};

	const handleCloseGallery = () => {
		
		setGalleryOpen(false);
		lastFocus.focus();
	};

	const handleCloseClick = () => {
		
		handleCloseGallery();
	};

	var parsedURL = url.parse(props.figures.figureImg_en);
	var updatedURL = domainURL + parsedURL.pathname;

	const IMAGES = [
		{
			src: updatedURL,
			thumbnail: updatedURL,
			thumbnailWidth: 600,
			thumbnailHeight: 250,
			caption: props.figures.content_en,
		},
	];

	const [modalShow, setModalShow] = useState(false);

	return (
		<>
			<Card>
				<Container>
					<Row style={{ "text-align": "center!important" }}>
						<Col>
							<div
								style={{
									"margin-left": "auto",
									width: "100%",
									"padding-top": "10px",
								}}
							>
								<div
									className="modal show"
									style={{ display: "block", position: "initial" }}
								>
									<Modal
										role="dialog"
										aria-labelledby="figureHeader"
										aria-modal="true"
										show={isGalleryOpen}
										size="xl"
										onHide={() => handleCloseClick()}
										centered
										dialogClassName="custom-modal"
										style={{ background: "#14141465" }}
										backdrop="static"
									>
										<Modal.Header
											style={{ display: "flex", justifyContent: "flex-end" }}
										>
											
											
												<button
													id="closeButton"
													className="close_1fc7pjq"
													onClick={handleCloseClick}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														height="40"
														width="40"
														viewBox="0 0 384 512"
														role="img"
														aria-labelledby="closeButtonText"
													>
														<title id="closeButtonText">Close (esc)</title>
														<path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
													</svg>
												</button>
											
										</Modal.Header>
										<Modal.Body>
										<div width='100%'style={{textAlign: 'left', justifyContent: 'start'}}><h2 id="figureHeader">Lesson Figure</h2></div>
											{IMAGES.map((image) => (
												<img
													key={image.src}
													src={image.thumbnail}
													alt={image.caption}
													onClick={() => handleOpenGallery()}
													width="100%"
													height="100%"
												/>
											))}
										</Modal.Body>
										<Modal.Footer>
											<div
												style={{ color: "black" }}
												dangerouslySetInnerHTML={{
													__html: props.figures.content_en,
												}}
											/>
										</Modal.Footer>
									</Modal>
								</div>

								<button
								 style={{borderColor: 'transparent', backgroundColor: 'transparent'}}
								onClick={() => {
									
									 handleOpenGallery();
								}}>

								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{IMAGES.map((image) => (
										<img
											key={image.src}
											src={image.thumbnail}
											alt={image.caption}
											width="100%"
										/>
									))}
								</div>
								</button>
							</div>

							<div
								style={{ "text-align": "right", "padding-bottom": "5px" }}
								className="text-muted"
							>
								Click Image to Enlarge
							</div>
						</Col>
					</Row>
					<Col></Col>
				</Container>
				<Card.Footer className="text-muted">
					{" "}
					<div className="text-muted">
						<div
							dangerouslySetInnerHTML={{ __html: props.figures.content_en }}
						/>
					</div>
				</Card.Footer>
			</Card>
		</>
	);
}
