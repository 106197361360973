
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import moment from 'moment';


import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import CreateNoteComponent from './create-note'
import { getBookmarkByStudent } from './services/getBookmarks'
import {getNotesByStudent} from './services/getNotes';

function StudentNotesMainComponenet(props) {

  const [bookmarks, setBookmarks] = useState([]);
  const [notes, setNotes] = useState([]);


  const getAllBookmarks = async () => {
    const bookmarkList = await getBookmarkByStudent();

    var newArray = [];
    newArray = bookmarkList.payload;

    // console.log("bookmarksJSON" + JSON.stringify(bookmarkList.payload));

    await setBookmarks(() => {
      return (
        <>
          {bookmarkList.map(bookmark => (
            <>
              <Card bg="light" key="light" style={{ margin: "10px" }}>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <h2 style={{fontSize: '20px'}}>{bookmark.module}</h2><br />
                      <span><h3 style={{ 'font-size': "12px" }}>{bookmark.unit}</h3><br />
                       <h3 style={{ 'font-size': "12px" }}>{bookmark.chapter}</h3></span>
                    </Col>
                    <Col style={{ 'text-align': "center", margin: "auto" }}>
                      <Button variant="outline-primary" href={"module?id=" + bookmark.moduleId}>Go to Bookmark</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          ))}
        </>
      )
    });
  }

  const getAllNotes = async () => {
    const noteList = await getNotesByStudent();
    var newArray = [];
    newArray = noteList.payload;
    await setNotes(() => {
      return (
        <>
          {noteList.map(note => (
            <>
              <Accordion defaultActiveKey="0">
                <Accordion.Item>
                  <Accordion.Header>
                    {note.summary} / {moment(note.date).format("LLLL")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {<div dangerouslySetInnerHTML={{ __html: note.notes }} />}
                      </div><br />
                    <Card className="text-center">
                      <Card.Footer style={{ 'text-align': "left" }} className="text-muted">
                        <div>{note.module}</div>
                      </Card.Footer>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div align="center">
                <hr width="75%" />
              </div>
            </>
          ))}
        </>
      )
    });
  }


  useEffect(async () => {
    // getUserInfo()
    await getAllBookmarks();
    await getAllNotes();
  }, []);

  return (
    <>
      <div align="right"> <CreateNoteComponent /> </div><br />
      <Tabs role="tab" defaultActiveKey="notes" id="fill-tab-example" className="mb-3" style={{ 'flex-direction': "row" }} fill>
        <Tab role="tabpanel" aria-labelledby="Notes" eventKey="notes" title="My Notes" style={{ padding: "10px" }}>

         {notes}

        </Tab>

        <Tab role="tabpanel" aria-labelledby="Bookmarks" eventKey="bookmarks" title="My Bookmarks">
          {bookmarks}
        </Tab>
      </Tabs>


    </>

  );
}

export default StudentNotesMainComponenet;