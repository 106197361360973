import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import LessonContentComponent from './lessonContents';
import { getModuleByID } from '../../services/bffServices/textbookBFFServices/getModuleDetails';
import React, { useState, useEffect } from "react";
import { getUserProgressByItemId } from '../../services/bffServices/getUserDetails';
import QuizComponent from '../textbook/quizComponent';
import { NavItem } from 'react-bootstrap';
const queryParams = new URLSearchParams(window.location.search);
const language = sessionStorage.getItem("lang") || "EN"

function LessonComponent(props) {
  const [lessonList, setLessonsList] = useState();
  const [lessonContent, setLessonsContent] = useState();
  const [lessonCompleteInd, setlessonCompleteInd] = useState();
  const [key, setKey] = useState(0);
  const [maxKey, setMaxKey] = useState();
  const [quizId, setQuizId] = useState(props.id);
  const [nextMod, setNextMod] = useState();
  const [modalShow, setModalShow] = useState(props.nextModuleId)
  const moduleInfo = async () => {
    const moduleInfo = await getModuleByID(props.moduleId)
    moduleInfo[0].lessons.sort(function (a, b) {
      return a.order - b.order;
    });

    const moduleLessonList = Object.keys(moduleInfo[0].lessons).map((key) => {
      var test = getUserProgressByItemId("LESSON", moduleInfo[0].lessons[key].id);
      setlessonCompleteInd(test)
      if (props.lessonId != false) {
        if (props.lessonId == moduleInfo[0].lessons[key].id) {
          setKey(key);

        }
      }

      return (
        <button className='chapterButton'>
          <Nav.Item>
            <Nav.Link style={{ cursor: "default" }} eventKey={key} >
              {lessonCompleteInd == "COMPLETE" &&
                <>
                  &#10003;
                </>
              }
              {language != "FR" &&
                <small>{moduleInfo[0].lessons[key].title_en}</small>
              }
              {language == "FR" &&
                <small>{moduleInfo[0].lessons[key].title_fr}</small>
              }
            </Nav.Link>
          </Nav.Item>
        </button>
      )
    });

    setLessonsList(moduleLessonList);
    const moveToNextLesson = (lessonKey, maxKey2, nexyMod) => {
      if (lessonKey <= maxKey2) {
        setKey(lessonKey);
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });

        if (lessonKey === maxKey2) {
          sessionStorage.setItem('lastLesson', "Y");
        } else {
          sessionStorage.setItem('lastLesson', "N");
        }
      } else {
        if (props.nextModuleId !== "END") {
          window.location.href = "/textbook/module?id=" + props.nextModuleId;
        }
        if (props.nextModuleId === "END") {
          window.location.href = "/textbook/module?id=" + props.moduleId + "&chapterComplete=true";
        }
      }
    }

    const moduleLessonContent = Object.keys(moduleInfo[0].lessons).map(key =>
      <>
        <Tab.Pane eventKey={key} sticky="top">
          <LessonContentComponent id={moduleInfo[0].lessons[key].id} eventChange={moveToNextLesson} nextModuleInd={props.nextModuleId} nextLesson={key} maxKey={moduleInfo[0].lessons.length - 1} />
        </Tab.Pane>
      </>
    );
    setLessonsContent(moduleLessonContent);
  }

  useEffect(async () => {
    try {
      if (!props.lessonId) {
        setKey(0);
      }
      setQuizId(props.id);
      setMaxKey(4);
      moduleInfo();

      await setNextMod(props.nextModuleId)
    } catch (err) {
      console.log(err);
    }
  }, [props.nextModuleId])

  return (
    <div style={{ padding: "0px" }}>
      <a href="#top" />
      <Tab.Container id="left-tabs-example" defaultActiveKey={key} activeKey={key} onSelect={(k) => setKey(k)}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column" >
              {lessonList}
              <Nav.Item>
                <div className="d-grid gap-2">
                  {props.id != "NONE" &&
                    <QuizComponent location="MODULE" moduleId={queryParams.get('id')} lessonId={"N/A"} id={props.id} setNav={props.setNav} />
                  }
                </div>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {lessonContent}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default LessonComponent;