import React from "react";

function ProductCallout({ title, description, link, onClick,linkText, image, isMobile}) {
	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			<div>
				<img
					src={
						image ??
						"https://www.firstbenefits.org/wp-content/uploads/2017/10/placeholder.png"
					}
					style={{ width: "150px", height: "150px", objectFit: "cover" }}
				/>

				<h3 style={{ textAlign: "center" }}>{title}</h3>
				<br />
				<p style={{ textAlign: "center", width: "350px" }}>{description}</p>
				<br />
				<a href={link ?? null}>
					<button onClick={onClick} className="header-button">{linkText}</button>
				</a>
			</div>
		</div>
	);
}

export default ProductCallout;
