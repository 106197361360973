import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UpdateButtonComponent from '../textbook/updateButtonComponenet';
import LessonFigures from '../textbook/lessonFigures';
import QuizComponent from '../textbook/quizComponent';
import { getLessonByID } from '../../services/bffServices/textbookBFFServices/getLessonDetails';
import React, { useState, useEffect } from "react";
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';
import ReactPlayer from 'react-player'
import { updateUserProgress } from '../../services/bffServices/textbookBFFServices/updateUserProgress'
import { getUserProgressByItemId } from '../../services/bffServices/getUserDetails';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
const url = require('url');
const queryParams = new URLSearchParams(window.location.search);
const moduleId = queryParams.get('id') || "";

const domainURL = "https://storage.googleapis.com/sb-general"

function LessonContentComponent(props) {
    const [lessonTitle, setLessonsTitle] = useState();
    const [lessonBody, setLessonsBody] = useState();
    const [lessonQuiz, setLessonsQuiz] = useState();
    const [lessonVideo, setLessonsVideo] = useState();
    const [lessonVideoPoster, setLessonsVideoPoster] = useState();
    const [lessonFigures, setLessonFigures] = useState([]);
    const [audioFile, setAudioFile] = useState();
    const [interactiveStructures, setInteractiveStructures] = useState();
    const [interactiveStructures2, setInteractiveStructures2] = useState();
    const [interactiveStructures3, setInteractiveStructures3] = useState();
    const [interactiveStructuresDesc_EN, setInteractiveStructuresDesc_EN] = useState();
    const [videoFramePost, setViceoFramePoster] = useState();
    const [lessonAudioLabel, setLessonAudioLabel] = useState("Lesson Audio");
    const [interStrucLabel, setInterStrucLabel] = useState("Interactive Structure / Structure interactive");
    const [figuresLabel, setFiguresLabel] = useState(() => {
        if (sessionStorage.getItem("lang") != "FR") {
            return "Figures"
        }
        if (sessionStorage.getItem("lang") == "FR") {
            return "Les Figures";
        }
    });
    const [lessonTextLabel, setLessonTextLabel] = useState(() => {
        if (sessionStorage.getItem("lang") != "FR") {
            return "Lesson Text"
        }
        if (sessionStorage.getItem("lang") == "FR") {
            return "Texte de la leçon";
        }
    });
    var language = sessionStorage.getItem("lang") || "EN"
    const handURL = '<img loading="lazy" class="size-full wp-image-23264 alignleft" src="' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol.jpg" alt="interact gesture" width="50" height="50" srcset="' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol-24x24.jpg 24w, ' + domainURL + '/wp-content/uploads/2019/01/Interactive-Hand-Symbol.jpg 62w" sizes="(max-width: 50px) 100vw, 50px"></img>'
    const id = props.id;

    const LessonInfo = async () => {

        const lessonDetails = await getLessonByID(id);
        if (language != "FR") {
            setLessonsTitle(lessonDetails[0]?.title_en);
            setLessonsBody(lessonDetails[0]?.lessonText_en || "NONE");
            setLessonsQuiz(lessonDetails[0]?.quiz || "NONE");
            setLessonsVideo(lessonDetails[0]?.videoURL_en || "NONE");
            setAudioFile(lessonDetails[0]?.audioFile || "NONE");
            setViceoFramePoster("#t=2")
            setLessonAudioLabel("Lesson Audio")
            setInterStrucLabel("Interactive Structure")
        }

        if (language == "FR") {
            setLessonsTitle(lessonDetails[0]?.title_fr);
            setLessonsBody(lessonDetails[0]?.lessonText_fr || "NONE");
            setLessonsQuiz(lessonDetails[0]?.quiz || "NONE");
            setLessonsVideo(lessonDetails[0]?.videoURL_fr || "NONE");
            setAudioFile(lessonDetails[0]?.audioFile_fr || "NONE");
            setViceoFramePoster("#t=10")
            setLessonAudioLabel("Audio de la leçon")
            setInterStrucLabel("Structure interactive")
        }

        setInteractiveStructures(lessonDetails[0]?.interactiveStructure || "NONE");
        setInteractiveStructures2(lessonDetails[0]?.interactiveStructure2 || "NONE");
        setInteractiveStructures3(lessonDetails[0]?.interactiveStructure3 || "NONE");
        if (lessonDetails[0].videoPoster_en != null || lessonDetails[0].videoPoster_en != undefined) {
            var parsedURL = url.parse(lessonDetails[0].videoPoster_en);
            var updatedURL = domainURL + parsedURL.pathname;
            setLessonsVideoPoster(updatedURL);
        }

        if (lessonDetails[0]?.interactiveStructureDesc_en != null || lessonDetails[0]?.interactiveStructureDesc_en != undefined) {
            const searchString = lessonDetails[0]?.interactiveStructureDesc_en;
            const searchRegex = /(<img .*?>)/;
            const updatedString = searchString.replace(searchRegex, '');
            setInteractiveStructuresDesc_EN(lessonDetails[0]?.interactiveStructureDesc_en || "NONE")
            setInteractiveStructuresDesc_EN(updatedString || "NONE")
        } else {
            setInteractiveStructuresDesc_EN(lessonDetails[0]?.interactiveStructureDesc_en || "NONE")
        }
        if (lessonDetails[0]?.lessonFigures != null || lessonDetails[0]?.lessonFigures != undefined) {
            const figureList = () => {
                return (
                    <>
                        {lessonDetails[0]?.lessonFigures.length > 0 &&
                            <>
                                <div><h3 style={{ color: "#054bb4", 'font-size': "25px" }}>{figuresLabel}</h3></div>
                            </>
                        }
                        {Object.keys(lessonDetails[0]?.lessonFigures).map(key =>
                            <>
                                <LessonFigures figures={lessonDetails[0]?.lessonFigures[key]} /><br />
                            </>
                        )}
                    </>
                );
            };

            var figures001 = await figureList();
            setLessonFigures(figures001);
        }

        var lessonStatus = await getUserProgressByItemId("LESSON", id);

        if (lessonStatus == "Not Started") {
            updateUserProgress("LESSON", id, 'IN-PROGRESS');
        }
    }

    const moveToNextLesson = (next, max) => {
        props.eventChange(next, max, props.nextModule)
    }

    useEffect(async () => {
        try {

            await LessonInfo();
        } catch (err) {
            console.log(err);
        }


        const removeAttribute = (attribute, name) => {
            const elementWithAttribute = document.querySelector(
              attribute
            );
      
            if (elementWithAttribute) {
        
             
        
              elementWithAttribute.removeAttribute({name})
      
              return elementWithAttribute;
            }
          };

        removeAttribute('[aria-haspopup="menu"]', 'aria-haspopup');


    }, [props.nextModuleId])

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    

    useEffect(() => {
			var elements = document.getElementsByClassName("video-react-button");

			for (var i = 0; i < elements.length; i++) {
				elements[i].removeAttribute("aria-live");
			}

           elements = document.getElementsByClassName("tab pane")

           for (var i = 0; i < elements.length; i++) {
            elements[i].setAttribute("role", "tabpanel");
            elements[i].setAttribute("aria-labeledby", id);
        }
		});

    return (
        <div role='tabpanel' aria-labelledby="lessongContent" style={{ padding: "0px" }}>
            <span style={{ padding: "0px", 'text-align': "center" }}>
                <h3 >{lessonTitle}</h3>
            </span><br /><br />
            <Container style={{ 'padding-left': "0", 'padding-right': "0" }}>
                <Row>
                    <Col sm={12}>
                        {lessonVideo != "NONE" && //need to make into own component
                            <Container>
                                <Row>
                                    <Player
                                        playsInline
                                        src={lessonVideo + videoFramePost}
                                        preload="metadata"
                                        onContextMenu={handleContextMenu}
                                        poster={lessonVideoPoster}
                                    >
                                        <BigPlayButton position="center" />
                                        <ControlBar>
                                            <ReplayControl seconds={10} order={1.1} />
                                            <ForwardControl seconds={30} order={1.2} />
                                            <CurrentTimeDisplay order={4.1} />
                                            <TimeDivider order={4.2} />
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                            <VolumeMenuButton enabled />
                                        </ControlBar>
                                    </Player>
                                </Row>
                            </Container>
                        }
                        {audioFile != "NONE" && //need to make into own component
                            <>
                                <ReactPlayer
                                    url={audioFile}
                                    height="30px"
                                    width="100%"
                                    controls="true"
                                    aria-label="Audio media player"
                                    role="region"
                                    aria-hidden="false"
                                />
                                <div style={{ 'padding-left': "20px", 'padding-top': "5px" }}><h4><strong>

                                    {lessonAudioLabel}

                                </strong></h4></div>
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col><br />
                        <div align="center" >
                            <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {lessonFigures != "NONE" &&
                            <>
                                {lessonFigures}
                            </>
                        }
                        {interactiveStructures != "NONE" &&
                            <>
                                <Container>
                                    <Row>
                                        <Col><br />
                                            <div align="center" >
                                                <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h2 style={{ fontSize: "18" }}>
                                                <span style={{ color: "#054bb4", 'font-size': "25px" }}><strong>{interStrucLabel}</strong></span></h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div dangerouslySetInnerHTML={{ __html: handURL }} /><ReactMarkdown rehypePlugins={[rehypeRaw]} >{interactiveStructuresDesc_EN}</ReactMarkdown>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ position: "relative" }}>
                                                <iframe loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures} width="100%" height="500px">
                                                    <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                    <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                    <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                        <canvas style={{ width: "100%", height: "500px!important" }}></canvas></div>
                                                </iframe><br />
                                                <div style={{ 'text-align': "right" }}>
                                                    <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures} target="_blank">Click here for full screen</a>
                                                </div>
                                            </div><br /><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {interactiveStructures2 != "NONE" &&
                                                <>
                                                    <div style={{ position: "relative" }}>
                                                        <iframe loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures2} width="100%" height="500px">
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                            <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                                <canvas style={{ width: "100%", height: "500px!important" }}></canvas></div>
                                                        </iframe><br />
                                                        <div style={{ 'text-align': "right" }}>
                                                            <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures2} target="_blank">Click here for full screen</a>
                                                        </div>
                                                    </div><br /><br />
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    {interactiveStructures3 != "NONE" &&
                                        <Row>
                                            <Col>
                                                <>
                                                <div style={{ position: "relative" }}>
                                                        <iframe loading="lazy" style={{ visibility: "visible", opacity: "1" }} src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures3} width="100%" height="500px">
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/vendor.75c1a58f3a7314a88499.js"></script>
                                                            <script type="text/javascript" src="https://storage.googleapis.com/interactive-structures/protein-viewer/main.75c1a58f3a7314a88499.js"></script>
                                                            <div class="whs-app" style={{ width: "inherit", height: "inherit", position: "relative" }}>
                                                                <canvas style={{ width: "100%", height: "500px!important" }}></canvas></div>
                                                        </iframe><br />
                                                        <div style={{ 'text-align': "right" }}>
                                                            <a href={"https://storage.googleapis.com/interactive-structures/protein-viewer/index.html?model=objects/" + interactiveStructures3} target="_blank">Click here for full screen</a>
                                                        </div>
                                                    </div><br /><br />
                                                </>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                            </>
                        }
                    </Col>
                </Row>

                {lessonBody != "NONE" &&
                    <>
                        <Row>
                            <Col>
                                <div align="center" >
                                    <hr style={{ width: "25%", borderTop: "3px dashed #bbb" }} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <h2 style={{ fontSize: "18" }}>
                                <span style={{ color: "#054bb4", 'font-size': "25px" }}><strong>{lessonTextLabel}</strong></span>
                            </h2>
                            <Col>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} >{lessonBody}</ReactMarkdown>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <Container align="right" style={{ 'margin-top': "100px" }}>
                <table>
                    <tr>
                        <td align="right">
                            <UpdateButtonComponent nextModuleInd={props.nextModuleId} type="LESSON" id={id} status="COMPLETE" eventFunction={moveToNextLesson} nextLesson={props.nextLesson} maxKey={props.maxKey} />
                        </td>
                        <td>
                            {lessonQuiz != "NONE" &&
                                <QuizComponent moduleId={moduleId} lessonId={id} id={lessonQuiz?.id} />
                            }
                        </td>
                    </tr>
                </table>
            </Container>
        </div>
    );
}

export default LessonContentComponent;